import { useEffect, useState } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { toast } from "react-hot-toast";
import { Box, Button, CircularProgress, CssBaseline, Grid, MenuItem, Paper, TextField } from "@mui/material";

import LoginIcon from "@mui/icons-material/Login";
import BannerImage from "assets/images/banner.jpg";
import GoogleIcon from "assets/images/google.png";
import LogoWithText from "assets/images/logo-with-text.png";

import { useUserLoginMutation } from "api/AuthApiSlice";
import axios from "axios";

const MANAGER_EMAILS: any = process.env.REACT_APP_RI_MANAGER_EMAILS || [];
const ACCOUNTANT_EMAILS: any = process.env.REACT_APP_RI_ACCOUNTANT_EMAILS || [];
const ADMIN_EMAILS: any = process.env.REACT_APP_RI_ADMIN_EMAILS || [];

const GoogleLoginComponent = () => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [accessToken, setAccessToken] = useState<string>("");
  const [userType, setUserType] = useState<string>("");
  const [isAdmin, setAdmin] = useState<boolean>(false);

  const [userLogin] = useUserLoginMutation();

  useEffect(() => {
    if (accessToken !== "" && userType !== "" && !isAdmin) {
      loginUser();
    }
  }, [accessToken, userType]);

  const login = useGoogleLogin({
    onSuccess: (response) => onSuccess(response),
    onError: (error) => onFailure(error),
  });

  const onSuccess = (tokenResponse: any) => {
    let googleToken = tokenResponse?.access_token ? tokenResponse?.access_token : "";
    setAccessToken(googleToken);

    if (tokenResponse?.access_token) {
      axios({
        method: "GET",
        url: "https://www.googleapis.com/oauth2/v3/userinfo",
        params: { access_token: googleToken },
      })
        .then((response: any) => {
          let userEmail: string = response?.data?.email || "";

          if (MANAGER_EMAILS.includes(userEmail)) {
            setUserType("manager");
            setAdmin(false);
          } else if (ACCOUNTANT_EMAILS.includes(userEmail)) {
            setUserType("accountant");
            setAdmin(false);
          } else if (ADMIN_EMAILS.includes(userEmail)) {
            setUserType("admin");
            setAdmin(true);
          } else {
            setUserType("employee");
            setAdmin(false);
          }
        })
        .catch(() => {
          setLoading(false);
          toast.error("Unable to get google profile");
        });
    } else {
      toast.error("Authentication failed!");
    }
  };

  const loginUser = () => {
    setLoading(true);

    userLogin({ user: { access_token: accessToken, login_as: userType } })
      .unwrap()
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  const onFailure = (response: any) => {
    let message = response?.details
      ? "Cookies are not enabled in your browser. Please enable it and try again."
      : "Google login failed";

    if (message !== "") {
      toast.error(message);
    }
  };

  return (
    <>
      <Grid item container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${BannerImage})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} sx={{ height: "100vh", position: "relative" }}>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              margin: "0",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: { xs: "90%", md: "50%" },
            }}
          >
            <img src={LogoWithText} alt="logo" style={{ width: "200px", marginBottom: "1rem" }} />
            <Box sx={{ width: "100%", marginBottom: "2rem" }}>
              <h2 style={{ fontWeight: "500" }}>Hello! let's get started</h2>
              <h4 style={{ fontWeight: "500" }}>Sign in to continue.</h4>
            </Box>
            {["admin", "manager"].includes(userType) && isAdmin ? (
              <>
                <TextField
                  label="Login as"
                  onChange={(e) => setUserType(e.target.value)}
                  sx={{ marginBottom: "2rem" }}
                  value={userType}
                  required
                  fullWidth
                  select
                  defaultValue={"admin"}
                >
                  <MenuItem value={"admin"}>{"Admin"}</MenuItem>
                  <MenuItem value={"manager"}>{"Manager"}</MenuItem>
                </TextField>
                <Button
                  type="button"
                  variant="contained"
                  onClick={loginUser}
                  disabled={isLoading}
                  sx={{
                    color: "#404041 !important",
                    backgroundColor: "rgba(255, 255, 255, 0.9) !important",
                    width: "100% !important",
                    paddingLeft: "0.9rem !important",
                    paddingRight: "0.9rem !important",
                    display: "grid",
                    gridTemplateColumns: "0 12fr",
                    textTransform: "none",
                  }}
                >
                  {isLoading ? <CircularProgress color="inherit" size={20} /> : <LoginIcon />}
                  <p>Sign in</p>
                </Button>
              </>
            ) : (
              <Button
                type="button"
                variant="contained"
                onClick={() => login()}
                disabled={isLoading}
                sx={{
                  color: "#404041 !important",
                  backgroundColor: "rgba(255, 255, 255, 0.9) !important",
                  width: "100% !important",
                  paddingLeft: "0.9rem !important",
                  paddingRight: "0.9rem !important",
                  display: "grid",
                  gridTemplateColumns: "0 12fr",
                  textTransform: "none",
                }}
              >
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  <img src={GoogleIcon} alt="Google" style={{ width: "24px", height: "24px" }} />
                )}
                <p>Sign in with Google</p>
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default GoogleLoginComponent;

import { makeStyles } from "@mui/styles";

export const MiniDrawerStyles = makeStyles(() => ({
  toolbarStyles: {
    backgroundColor: "#fff",
    flexGrow: "1",
  },
  sidebarItemActive: {
    backgroundColor: "#13add5 !important",
    color: "#fff !important",
    "&:hover": {
      backgroundColor: "#13add5 !important",
      color: "#fff !important",
    },
  },
  sidebarItemInActive: {
    backgroundColor: "#f9f9f9",
  },
}));

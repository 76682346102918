import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import type { RootState } from "app/store";
import _ from "lodash";

const API_BASE_URL = process.env.REACT_APP_RI_API_BASE_URL;

export const ProjectApi = createApi({
  reducerPath: "projectAPIs",
  baseQuery: fetchBaseQuery({
    baseUrl: API_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const jwtToken = (getState() as RootState).auth?.jwtToken;

      if (jwtToken) {
        headers.set("Authorization", jwtToken);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getProjects: builder.mutation({
      query: () => ({
        url: "projects",
        method: "GET",
      }),
      transformResponse: (response: any, _meta: any) => {
        let apiResponse = response?.data;
        let projects: any = [];

        if (_.isArray(apiResponse)) {
          projects = apiResponse.map((project: any) => {
            return {
              id: project?.id,
              name: project?.project_name,
              isActive: project?.is_active,
            };
          });
        }

        return projects;
      },
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (err: any) {
          console.error(err);
        }
      },
    }),
    getProject: builder.mutation({
      query: (body) => ({
        url: `/projects/${body?.id}`,
        method: "GET",
      }),
      transformResponse: (response: any, _meta: any) => {
        let apiResponse = response?.data ? response?.data : {};

        return {
          id: apiResponse?.id,
          name: apiResponse?.project_name,
          isActive: apiResponse?.is_active,
        };
      },
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (err: any) {
          console.error(err);
        }
      },
    }),
    createProject: builder.mutation({
      query: (body) => ({
        url: "/projects",
        method: "POST",
        body: body,
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (err: any) {
          console.error(err);
        }
      },
    }),
    updateProject: builder.mutation({
      query: (body) => ({
        url: `/projects/${body?.id}`,
        method: "PUT",
        body: body?.data,
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (err: any) {
          console.error(err);
        }
      },
    }),
    deleteProject: builder.mutation({
      query: (body) => ({
        url: `/projects/${body?.id}`,
        method: "DELETE",
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (err: any) {
          console.error(err);
        }
      },
    }),
  }),
});

export const {
  useGetProjectsMutation,
  useGetProjectMutation,
  useCreateProjectMutation,
  useUpdateProjectMutation,
  useDeleteProjectMutation,
} = ProjectApi;

import { useEffect, useState } from "react";
import { Box, Button, SwipeableDrawer, useMediaQuery, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { toast } from "react-hot-toast";

import AddIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
// import DeleteIcon from "@mui/icons-material/Delete";

import Datatable from "components/Common/Datatable";
import Project from "components/Common/Project";
import PopupDialog from "components/Common/PopupDialog";

import { useGetProjectsMutation, useDeleteProjectMutation } from "api/ProjectApiSlice";

import { setBackdropState } from "store/uiSlices";
import { useAppDispatch } from "app/hooks";

const useStyles = makeStyles({
  backButton: {
    width: "35px",
    height: "35px",
  },
  actionDiv: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media (max-width: 425px)": {
      width: "auto",
    },
  },
  thumbnailImage: {
    aspectRatio: 1,
    objectFit: "cover",
    width: "4rem",
  },
  buttonAction: {
    margin: "0 0.5rem !important",
    minWidth: "120px !important",
    "@media (max-width: 900px)": {
      minWidth: "50px !important",
    },
    "&:disabled": {
      color: "#fff",
    },
  },
  anchorTag: {
    textDecoration: "none",
    color: "#71abd5",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  drawer: {
    width: "40%",
  },
});

const ProjectsPage = () => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const isLessThanTabletScreen = useMediaQuery(theme.breakpoints.down("md"));

  const showBackdrop = (state: boolean) => dispatch(setBackdropState(state));

  const [projectData, setProjectData] = useState<any>([]);
  const [formData, setFormData] = useState<any>({});
  const [dataLength, setDataLength] = useState<number>(0);
  const [actionType, setActionType] = useState<string>("Create");
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 100,
  });
  const [showForm, setShowForm] = useState<boolean>(false);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  // const [projectId, setProjectId] = useState<number>(0);
  const projectId = 1;

  const [getProjects, { isLoading }] = useGetProjectsMutation();
  const [deleteProject] = useDeleteProjectMutation();

  useEffect(() => {
    getProjectsData();
  }, [paginationModel]);

  const getProjectsData = () => {
    getProjects({})
      .unwrap()
      .then((response: any) => {
        setProjectData(response);
        setDataLength(response?.length);
      });
  };

  const refetch = () => {
    setPaginationModel({
      page: 0,
      pageSize: 100,
    });
  };

  const handleEditClick = (row: any) => {
    setActionType("Update");
    setShowForm(true);
    setFormData(row);
  };

  const handleDeleteClick = () => {
    showBackdrop(true);

    deleteProject({ id: projectId })
      .unwrap()
      .then(() => {
        showBackdrop(false);
        setShowPopup(false);
        toast.success("Project deleted successfully");
        refetch();
      })
      .catch(() => showBackdrop(false));
  };

  const tableHeaders: any = [
    { field: "name", headerName: "Name", width: 200, sortable: false },
    {
      field: "isActive",
      headerName: "Status",
      width: 100,
      sortable: false,
      renderCell: (params: any) => {
        const isActive = params?.row?.isActive;

        return <Box>{isActive ? "Active" : "Inactive"}</Box>;
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: isLessThanTabletScreen ? 100 : 200,
      sortable: false,
      headerAlign: "center",
      renderCell: (params: any) => {
        return (
          <Box className={classes.actionDiv}>
            <Button
              className={classes.buttonAction}
              size="small"
              variant="contained"
              onClick={() => handleEditClick(params?.row)}
            >
              {isLessThanTabletScreen ? <EditIcon /> : <>Edit</>}
            </Button>
            {/* <Button
              className={classes.buttonAction}
              size="small"
              variant="contained"
              color="error"
              onClick={() => {
                setProjectId(params?.row?.id);
                setShowPopup(true);
              }}
            >
              {isLessThanTabletScreen ? <DeleteIcon /> : <>Delete</>}
            </Button> */}
          </Box>
        );
      },
    },
  ];

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <h2>Projects</h2>
        <Button
          variant="contained"
          size="medium"
          onClick={() => {
            setShowForm(true);
            setActionType("Create");
          }}
        >
          {isLessThanTabletScreen ? <AddIcon /> : "Create Project"}
        </Button>
      </Box>
      <Box sx={{ width: "100%", padding: "1.5rem 0" }}>
        <Datatable
          type={"project"}
          isLoading={isLoading}
          headers={tableHeaders}
          data={projectData}
          rowCount={dataLength}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
        />
        {showPopup && (
          <PopupDialog
            popupOpen={showPopup}
            message={"Are you sure you want to delete this?"}
            handleDelete={handleDeleteClick}
            handleClose={() => setShowPopup(false)}
          />
        )}
        {showForm && (
          <SwipeableDrawer
            anchor={"right"}
            open={showForm}
            onOpen={() => setShowForm(true)}
            onClose={() => setShowForm(false)}
            sx={{ width: { xs: "100vw", md: "40vw" } }}
          >
            <Project actionType={actionType} formData={formData} setShowForm={setShowForm} refetch={refetch} />
          </SwipeableDrawer>
        )}
      </Box>
    </>
  );
};

export default ProjectsPage;

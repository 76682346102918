import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import type { RootState } from "app/store";
import _ from "lodash";

const API_BASE_URL = process.env.REACT_APP_RI_API_BASE_URL;

export const AnalyticsApi = createApi({
  reducerPath: "analyticsAPIs",
  baseQuery: fetchBaseQuery({
    baseUrl: API_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const jwtToken = (getState() as RootState).auth?.jwtToken;

      if (jwtToken) {
        headers.set("Authorization", jwtToken);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAnalytics: builder.mutation({
      query: (body) => ({
        url: body?.url,
        method: "GET",
      }),
      transformResponse: (response: any, _meta: any) => {
        let apiResponse = response?.data?.reimbursements;

        let totalCount = response?.data?.total_count || 10;
        let totalAmount = response?.data?.total_amount || "0.0";
        let reimbursements: any = [];

        if (_.isArray(apiResponse)) {
          reimbursements = apiResponse.map((reimbursement: any) => {
            return {
              id: reimbursement?.id,
              projectName: reimbursement?.project_name,
              username: reimbursement?.name,
              department: reimbursement?.department,
              dateOfRequest: reimbursement?.date_of_request,
              dateOfExpense: reimbursement?.date_of_expense,
              typeOfReimbursement: reimbursement?.type_of_reimbursement,
              amount: reimbursement?.amount,
              status: reimbursement?.is_exported ? "Paid" : "Processing",
            };
          });
        }

        return {
          totalCount: totalCount,
          totalAmount: totalAmount,
          reimbursements: reimbursements,
        };
      },
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (err: any) {
          console.error(err);
        }
      },
    }),
    getExcelData: builder.mutation({
      query: () => ({
        url: "/analytics/export_excel",
        method: "GET",
      }),
      transformResponse: (response: any, _meta: any) => {
        let apiResponse = response?.data;
        let returnResponse: any = [];

        if (_.isArray(apiResponse)) {
          returnResponse = apiResponse.map((excelData: any, index: number) => {
            return {
              paymentType: excelData?.payment_type,
              customerReferenceNumber: index + 1,
              sourceBankAccountNumber: excelData?.source_bank_account_number,
              sourceNaration: excelData?.source_naration,
              destinationBankAccountNumber: excelData?.destination_bank_account_number,
              destinationNaration: excelData?.destination_naration,
              currency: excelData?.currency,
              amount: excelData?.amount,
              destinationBankName: excelData?.destination_bank_name,
              destinationBankIfscCode: excelData?.destination_bank_ifsc_code,
              name: excelData?.name,
              destinationBankAccountType: excelData?.destination_bank_account_type,
              supportEmail: excelData?.support_email,
            };
          });
        }

        return returnResponse;
      },
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (err: any) {
          console.error(err);
        }
      },
    }),
    updateExcelData: builder.mutation({
      query: () => ({
        url: "/analytics/mark_downloaded",
        method: "PATCH",
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (err: any) {
          console.error(err);
        }
      },
    }),
  }),
});

export const { useGetAnalyticsMutation, useGetExcelDataMutation, useUpdateExcelDataMutation } = AnalyticsApi;

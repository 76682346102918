import { DataGrid } from "@mui/x-data-grid";

const Datatable = ({
  isLoading,
  headers,
  data,
  rowCount,
  paginationModel,
  setPaginationModel,
  onSortColumnChange,
}: any) => {
  const perPageOptions = [10, 20, 50, 100];

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <DataGrid
        loading={isLoading}
        columns={headers}
        rows={data}
        rowCount={rowCount}
        paginationModel={paginationModel}
        onPaginationModelChange={(paginationModel) => setPaginationModel(paginationModel)}
        onSortModelChange={(newSortModel) => onSortColumnChange(newSortModel)}
        paginationMode="server"
        pageSizeOptions={perPageOptions}
        autoHeight={true}
        disableColumnMenu={true}
        getRowHeight={() => "auto"}
        sx={{
          border: "none",
          "& .MuiDataGrid-columnSeparator": {
            display: "none",
          },
          "& .MuiDataGrid-selectedRowCount": {
            visibility: "hidden",
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold",
          },
          "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus": {
            outline: "none",
          },
          "& .MuiDataGrid-row.Mui-selected": {
            backgroundColor: "#fff",
          },
          "& .MuiDataGrid-cell": {
            py: 1,
          },
          "& .MuiDataGrid-cell:focus-within": {
            outline: "none",
          },
          "& .MuiInputBase-root": {
            margin: "0 1rem",
          },
          "& .MuiButton-textSizeSmall": {
            color: "#414042",
          },
        }}
      />
    </div>
  );
};

export default Datatable;

import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Toolbar,
  useMediaQuery,
  Button,
  AppBar,
  Drawer,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { useAppDispatch, useAppSelector } from "app/hooks";
import { setSidebarState } from "store/uiSlices";

import { AuthApi, useUserLogoutMutation } from "api/AuthApiSlice";

import DashboardLightIcon from "@mui/icons-material/Dashboard";
import DashboardFilledIcon from "@mui/icons-material/DashboardOutlined";
import ProjectLightIcon from "@mui/icons-material/ListAlt";
import ProjectLightFilledIcon from "@mui/icons-material/ListAltOutlined";
import ReimbursementLightIcon from "@mui/icons-material/LocalOffer";
import ReimbursementFilledIcon from "@mui/icons-material/LocalOfferOutlined";
import RequestLightIcon from "@mui/icons-material/RequestQuote";
import RequestFilledIcon from "@mui/icons-material/RequestQuoteOutlined";
import LogoutIcon from "@mui/icons-material/PowerSettingsNew";

import Logo from "assets/images/logo.svg";
import LogoWithText from "assets/images/logo-with-text.png";

import { DrawerHeader } from "components/Common/MiniDrawerStyles";
import TooltipAction from "components/Common/TooltipAction";

import { MiniDrawerStyles } from "styles/MiniDrawerStyle";

const MiniDrawer = () => {
  const theme = useTheme();
  const classes = MiniDrawerStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const isLessThanTabletScreen = useMediaQuery(theme.breakpoints.down("md"));

  const userRole = useAppSelector((state) => state.auth?.userData?.role);
  const userName = useAppSelector((state) => state.auth?.userData?.display_name);
  const sidebarOpen = useAppSelector((state) => state.ui?.isSidebarOpen);

  const openSidebar = (state: boolean) => dispatch(setSidebarState(state));

  const [userLogout] = useUserLogoutMutation();

  const sidebarItems = [
    {
      id: 1,
      categoryTitle: "Analytics",
      url: `/${userRole}/analytics`,
      icon: <DashboardLightIcon />,
      activeIcon: <DashboardFilledIcon />,
      subItems: [],
      isActive: ["admin"].includes(userRole) ? true : false,
    },
    {
      id: 2,
      categoryTitle: "Projects",
      url: `/${userRole}/projects`,
      icon: <ProjectLightIcon />,
      activeIcon: <ProjectLightFilledIcon />,
      subItems: [],
      isActive: ["admin"].includes(userRole) ? true : false,
    },
    {
      id: 3,
      categoryTitle: "My Reimbursements",
      url: `/${userRole}/reimbursements`,
      icon: <ReimbursementLightIcon />,
      activeIcon: <ReimbursementFilledIcon />,
      subItems: [],
      isActive: ["employee", "manager"].includes(userRole) ? true : false,
    },
    {
      id: 4,
      categoryTitle: "Requests",
      url: `/${userRole}/requests`,
      icon: <RequestLightIcon />,
      activeIcon: <RequestFilledIcon />,
      subItems: [],
      isActive: ["manager", "accountant", "admin"].includes(userRole) ? true : false,
    },
  ];

  useEffect(() => {
    if (isLessThanTabletScreen) {
      openSidebar(false);
    } else {
      openSidebar(true);
    }
  }, [isLessThanTabletScreen]);

  const handleToggleDrawer = () => {
    openSidebar(!sidebarOpen);
  };

  const handleSidebarItemLink = (url: string) => {
    if (isLessThanTabletScreen) {
      if (url !== "") {
        navigate(url);
        openSidebar(false);
      }
    } else {
      if (url !== "") {
        navigate(url);
        openSidebar(true);
      }
    }
  };

  const handleLogout = () => {
    userLogout({})
      .unwrap()
      .then(() => {
        dispatch(AuthApi.util.resetApiState());
        window.localStorage.clear();
      });
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <AppBar component="nav" sx={{ height: "64px" }}>
          <Toolbar classes={{ root: classes.toolbarStyles }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleToggleDrawer}
              sx={{ mr: 2 }}
            >
              <Box
                sx={{ height: "45px", cursor: { xs: "pointer" } }}
                component={"img"}
                src={isLessThanTabletScreen ? Logo : LogoWithText}
              />
            </IconButton>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {sidebarItems.map((sidebarItem) => (
                <Button
                  key={sidebarItem?.id}
                  style={{
                    fontWeight: 600,
                    fontSize: "16px",
                    textTransform: "capitalize",
                    display: sidebarItem?.isActive ? "block" : "none",
                    ...(location.pathname.includes(sidebarItem?.url) ? { color: "#45b6fe" } : { color: "#414042" }),
                  }}
                  onClick={() => handleSidebarItemLink(sidebarItem?.url)}
                >
                  {sidebarItem.categoryTitle}
                </Button>
              ))}
            </Box>
            <Box
              className="profile-name"
              sx={{
                flexGrow: 1,
                color: "#414042",
                textAlign: "right",
                display: { xs: "block", md: "block" },
              }}
            >
              {userName}
            </Box>
            <Box sx={{ marginLeft: "auto" }}>
              <TooltipAction title={"Logout"} placement={"bottom"} arrow={true}>
                <IconButton size="large" aria-label="logout" color="default" onClick={handleLogout}>
                  <LogoutIcon />
                </IconButton>
              </TooltipAction>
            </Box>
          </Toolbar>
        </AppBar>
        <Box component="nav">
          <Drawer
            variant="temporary"
            open={sidebarOpen}
            onClose={handleToggleDrawer}
            ModalProps={{ keepMounted: true }}
            sx={{
              display: { xs: "block", md: "none" },
              "& .MuiDrawer-paper": { boxSizing: "border-box", width: 240 },
            }}
          >
            <DrawerHeader>
              <Box
                sx={{ height: "45px", cursor: "pointer" }}
                component={"img"}
                src={LogoWithText}
                onClick={handleToggleDrawer}
              />
            </DrawerHeader>
            <Divider />
            <List>
              {sidebarItems.map((sidebarItem) => (
                <ListItem
                  key={sidebarItem?.id}
                  disablePadding
                  sx={{
                    display: sidebarItem?.isActive ? "block" : "none",
                    "&.MuiListItem-root:hover": {
                      bgcolor: "transparent",
                    },
                  }}
                  onClick={() => handleSidebarItemLink(sidebarItem?.url)}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: sidebarOpen ? "initial" : "center",
                      px: 2.5,
                    }}
                    classes={{
                      root: location.pathname.includes(sidebarItem.url)
                        ? classes.sidebarItemActive
                        : classes.sidebarItemInActive,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: sidebarOpen ? 3 : "auto",
                        justifyContent: "center",
                      }}
                      classes={{
                        root: location.pathname.includes(sidebarItem.url)
                          ? classes.sidebarItemActive
                          : classes.sidebarItemInActive,
                      }}
                    >
                      {location.pathname.includes(sidebarItem?.url) ? sidebarItem?.activeIcon : sidebarItem?.icon}
                    </ListItemIcon>
                    <ListItemText primary={sidebarItem?.categoryTitle} sx={{ opacity: sidebarOpen ? 1 : 0 }} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Drawer>
        </Box>
      </Box>
    </>
  );
};

export default React.memo(MiniDrawer);

import { useNavigate } from "react-router-dom";
import { Box, Button } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";

import NotFoundImage from "assets/images/page-not-found.png";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <>
      <Box sx={{ width: "100vw", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
        <Box sx={{ height: "75%" }} component={"img"} src={NotFoundImage} alt="404" />
        <Box sx={{ position: "absolute", marginTop: "75vh" }}>
          <Button size="medium" variant="contained" onClick={() => navigate("/")}>
            <HomeIcon />
            <div>Back to home</div>
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default NotFound;

import { useEffect, useState } from "react";
import { Box, Button, IconButton, InputAdornment } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { toast } from "react-hot-toast";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";

import Datatable from "components/Common/Datatable";
import { Search, StyledInputBase } from "components/Common/SearchbarStyles";

import { useGetAnalyticsMutation, useGetExcelDataMutation, useUpdateExcelDataMutation } from "api/AnalyticsApiSlice";

const useStyles = makeStyles({
  backButton: {
    width: "35px",
    height: "35px",
  },
  actionDiv: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media (max-width: 425px)": {
      width: "auto",
    },
  },
  amountDiv: {
    width: "100%",
    display: "flex",
    justifyContent: "right",
    alignItems: "right",
  },
  thumbnailImage: {
    aspectRatio: 1,
    objectFit: "cover",
    width: "4rem",
  },
  buttonAction: {
    margin: "0 0.5rem !important",
    minWidth: "120px !important",
    "@media (max-width: 900px)": {
      minWidth: "50px !important",
    },
    "&:disabled": {
      color: "#fff",
    },
  },
  anchorTag: {
    textDecoration: "none",
    color: "#71abd5",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  drawer: {
    width: "40%",
  },
});

const columnAliasNames: any = {
  typeOfReimbursement: "reimbursement_type",
  dateOfRequest: "created_at",
  dateOfExpense: "expense_date",
  amount: "amount",
  status: "is_exported",
};

const AnalyticsPage = () => {
  const classes = useStyles();

  const [analyticsData, setAnalyticsData] = useState<any>([]);
  const [dataLength, setDataLength] = useState<number>(0);
  const [sortColumn, setSortColumn] = useState<string>("");
  const [sortOrder, setSortOrder] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");
  const [excelData, setExcelData] = useState<any>([]);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const [getAnalytics, { isLoading }] = useGetAnalyticsMutation();
  const [getExcelReport] = useGetExcelDataMutation();
  const [markDownloaded] = useUpdateExcelDataMutation();

  useEffect(() => {
    if (analyticsData?.length > 0) getExcelData();
  }, [analyticsData]);

  useEffect(() => {
    getAnalyticsData();
  }, [paginationModel, sortColumn, sortOrder, searchText]);

  const getAnalyticsData = () => {
    getAnalytics({
      url: `/analytics?page=${
        paginationModel?.page + 1
      }&per_page=${paginationModel?.pageSize}&sort_column=${sortColumn}&sort_order=${sortOrder}&search=${searchText}`,
    })
      .unwrap()
      .then((response: any) => {
        setAnalyticsData(response?.reimbursements);
        setDataLength(response?.totalCount);
      });
  };

  const getExcelData = () => {
    getExcelReport({})
      .unwrap()
      .then((response: any) => setExcelData(response));
  };

  const refetch = () => {
    setPaginationModel({
      page: 0,
      pageSize: 10,
    });
    setSortColumn("");
    setSortOrder("");
    setSearchText("");
  };

  const handleExportClick = () => {
    if (excelData && excelData.length > 0) {
      let workbook = XLSX.utils.book_new();
      let sheetName = "Data";
      let worksheet = XLSX.utils.json_to_sheet([], { skipHeader: true }); // Empty data for now

      // Add headers to the worksheet
      let headers = excelHeaders.map((header) => header.label);
      XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: "A1" });

      // Add the data rows to the worksheet
      let dataRows = excelData.map((item: any) => excelHeaders.map((header) => item[header.key]));
      XLSX.utils.sheet_add_aoa(worksheet, dataRows, { origin: "A2" });

      let canvas = document.createElement("canvas");
      let ctx = canvas.getContext("2d");

      if (ctx) {
        let worksheetRef = worksheet["!ref"];
        let font = "12px Arial"; // You can adjust the font size and style here
        ctx.font = font;

        if (worksheetRef) {
          let cellRange = XLSX.utils.decode_range(worksheetRef);

          for (let col = cellRange.s.c; col <= cellRange.e.c; col++) {
            let maxCellWidth = 0;

            for (let row = cellRange.s.r; row <= cellRange.e.r; row++) {
              let cell = worksheet[XLSX.utils.encode_cell({ r: row, c: col })];

              if (cell?.t === "s" && cell?.v) {
                let textWidth = ctx.measureText(cell.v).width;

                if (textWidth > maxCellWidth) {
                  maxCellWidth = textWidth;
                }
              }
            }

            let desiredWidth = Math.ceil(maxCellWidth / 7) + 2; // Add some padding for better visibility and adjust the cell width based on your preference

            if (!worksheet["!cols"]) {
              worksheet["!cols"] = [];
            }

            worksheet["!cols"][col] = { wch: desiredWidth };
          }
        }
      }

      XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

      // Generate Excel file
      let excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

      // Save the file
      let excelDataBlob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      saveAs(excelDataBlob, "Report.xlsx");

      markDownloaded({})
        .unwrap()
        .then(() => {
          setExcelData([]);
          refetch();
        });
    } else {
      toast("No data to export", { icon: <InfoIcon color="info" /> });
    }
  };

  const handleSortColumnChange = (sortModel: any) => {
    if (sortModel?.length > 0) {
      setSortColumn(columnAliasNames[sortModel[0].field]);
      setSortOrder(sortModel[0].sort);
    } else {
      setSortColumn("");
      setSortOrder("");
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaginationModel({
      page: 0,
      pageSize: 10,
    });
    setSearchText(event.currentTarget.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && searchText !== "") {
      setPaginationModel({
        page: 0,
        pageSize: 10,
      });
    }
  };

  const handleCloseSearch = () => {
    setPaginationModel({
      page: 0,
      pageSize: 10,
    });
    setSearchText("");
  };

  const tableHeaders: any = [
    { field: "projectName", headerName: "Project Name", width: 150, sortable: false },
    {
      field: "username",
      headerName: "Username",
      width: 200,
      sortable: false,
      renderCell: (params: any) => {
        const username = params?.row?.username;

        return <Box style={{ wordBreak: "break-word" }}>{username}</Box>;
      },
    },
    { field: "department", headerName: "Department", width: 150, sortable: false },
    { field: "typeOfReimbursement", headerName: "Reimbursement Type", width: 200, sortable: true },
    { field: "dateOfRequest", headerName: "Request Date", width: 150, sortable: true },
    { field: "dateOfExpense", headerName: "Expense Date", width: 150, sortable: true },
    {
      field: "status",
      headerName: "Payment Status",
      width: 130,
      sortable: false,
      renderCell: (params: any) => {
        const status = params?.row?.status;

        return <Box className={`badge ${status === "Paid" ? "approved-badge" : "waiting-badge"}`}>{status}</Box>;
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 120,
      sortable: true,
      headerAlign: "right",
      renderCell: (params: any) => {
        const amount = params?.row?.amount;

        return <Box className={classes.amountDiv}>{amount}</Box>;
      },
    },
  ];
  const excelHeaders = [
    { label: "Payment Type", key: "paymentType" },
    { label: "Cust Ref Number", key: "customerReferenceNumber" },
    { label: "Source Account Number", key: "sourceBankAccountNumber" },
    { label: "Source Naration", key: "sourceNaration" },
    { label: "Destination Account Number", key: "destinationBankAccountNumber" },
    { label: "Destination Naration", key: "destinationNaration" },
    { label: "Currency", key: "currency" },
    { label: "Amount", key: "amount" },
    { label: "Destination Bank", key: "destinationBankName" },
    { label: "Destination Bank IFS Code", key: "destinationBankIfscCode" },
    { label: "Beneficiary Name", key: "name" },
    { label: "Beneficiary Account Type", key: "destinationBankAccountType" },
    { label: "Email", key: "supportEmail" },
  ];

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <h2>Analytics</h2>
        <Button variant="contained" size="medium" onClick={handleExportClick}>
          Export
        </Button>
      </Box>

      <Box sx={{ marginTop: "0.5rem" }}>
        <Search style={{ height: "40px" }}>
          <StyledInputBase
            placeholder="Search by project name, username, reimbursement type"
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            value={searchText}
            startAdornment={
              <InputAdornment position="start" sx={{ paddingLeft: "10px" }}>
                <SearchIcon />
              </InputAdornment>
            }
            endAdornment={
              searchText && (
                <IconButton aria-label="close" onClick={handleCloseSearch}>
                  <CloseIcon />
                </IconButton>
              )
            }
          />
        </Search>
      </Box>
      <Box sx={{ width: "100%", padding: "1.5rem 0" }}>
        <Datatable
          type={"analytics"}
          isLoading={isLoading}
          headers={tableHeaders}
          data={analyticsData}
          rowCount={dataLength}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
          onSortColumnChange={handleSortColumnChange}
        />
      </Box>
    </>
  );
};

export default AnalyticsPage;

import { Routes, Route, useLocation } from "react-router-dom";

import AuthenticationLayout from "layouts/AuthenticationLayout";
import EmployeeLayout from "layouts/EmployeeLayout";
import ManagerLayout from "layouts/ManagerLayout";
import AccountantLayout from "layouts/AccountantLayout";
import AdminLayout from "layouts/AdminLayout";

import LoginPage from "pages/Authentication/LoginPage";
import ProjectsPage from "pages/Common/ProjectsPage";
import AnalyticsPage from "pages/Common/AnalyticsPage";
import ReimbursementsPage from "pages/Common/ReimbursementsPage";
import RequestsPage from "pages/Common/RequestsPage";
import NotFound from "pages/NotFound";

const AppRoutes = () => {
  const location = useLocation();

  return (
    <Routes key={location.pathname} location={location}>
      <Route path="/" element={<AuthenticationLayout />}>
        <Route path="" element={<LoginPage />} />
        <Route path="auth">
          <Route path="login" element={<LoginPage />} />
        </Route>
      </Route>
      <Route path="employee" element={<EmployeeLayout />}>
        <Route path="reimbursements" element={<ReimbursementsPage />} />
      </Route>
      <Route path="manager" element={<ManagerLayout />}>
        <Route path="reimbursements" element={<ReimbursementsPage />} />
        <Route path="requests" element={<RequestsPage />} />
      </Route>
      <Route path="accountant" element={<AccountantLayout />}>
        <Route path="requests" element={<RequestsPage />} />
      </Route>
      <Route path="admin" element={<AdminLayout />}>
        <Route path="analytics" element={<AnalyticsPage />} />
        <Route path="projects" element={<ProjectsPage />} />
        <Route path="reimbursements" element={<ReimbursementsPage />} />
        <Route path="requests" element={<RequestsPage />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRoutes;

import { GoogleOAuthProvider } from "@react-oauth/google";

import AppRoutes from "routes/AppRoutes";

import "assets/css/style.scss";

const GOOGLE_APP_ID: string = process.env.REACT_APP_RI_GOOGLE_CLIENT_ID || "";

const App = () => {
  return (
    <>
      <GoogleOAuthProvider clientId={GOOGLE_APP_ID}>
        <AppRoutes />
      </GoogleOAuthProvider>
    </>
  );
};

export default App;

import { useEffect, useState } from "react";
import { pdfjs, Document, Page } from "react-pdf";
import { useMediaQuery, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
const FailedComponent = ({ text }: { text: string }) => {
  return <Box>{text}</Box>;
};

const PDFPreview = (props: any) => {
  const theme = useTheme();
  const isLessThanTabletScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isLessThanLargeScreen = useMediaQuery(theme.breakpoints.down("xl"));
  const [numPages, setNumPages] = useState(0);
  const [scale, setScale] = useState(1);
  const { pdf } = props;
  const type = props?.type ? props?.type : "page";

  useEffect(() => {
    if (isLessThanTabletScreen) {
      setScale(0.6);
    } else if (isLessThanLargeScreen) {
      setScale(1);
    } else {
      setScale(1.5);
    }
  }, []);

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
  };

  return (
    <>
      <div id={"pdf-viewer"} className={type === "page" ? "" : "pdf-viewer"}>
        <Document
          file={pdf}
          onLoadSuccess={onDocumentLoadSuccess}
          error={<FailedComponent text={"Failed to load PDF file."} />}
          onLoadError={(error) => {
            return <FailedComponent text={`${error}`} />;
          }}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <>
              <Page
                key={index}
                pageNumber={index + 1}
                scale={scale}
                renderAnnotationLayer={false}
                renderTextLayer={false}
                error={<div>Error</div>}
                onRenderSuccess={() => {}}
                onLoadSuccess={() => {}}
                onLoadError={(error) => alert("Error while loading Error! " + error.message)}
                onRenderError={(error) => alert("Error while Render page! " + error.message)}
              />
              <Box>
                Page {index + 1} of {numPages}
              </Box>
            </>
          ))}
        </Document>
      </div>
    </>
  );
};

export default PDFPreview;

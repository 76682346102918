import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import type { RootState } from "app/store";

import { login, logout } from "store/authSlices";

const API_BASE_URL = process.env.REACT_APP_RI_API_BASE_URL;

export const AuthApi = createApi({
  reducerPath: "authAPIs",
  baseQuery: fetchBaseQuery({
    baseUrl: API_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const jwtToken = (getState() as RootState).auth?.jwtToken;

      if (jwtToken) {
        headers.set("Authorization", jwtToken);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    userLogin: builder.mutation({
      query: (body) => ({
        url: "/users/sign_in",
        method: "POST",
        body: body,
      }),
      transformResponse: (response: any, meta) => {
        return {
          jwtToken: meta?.response?.headers.get("Authorization"),
          userData: response?.data,
        };
      },
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          dispatch(login(data));
        } catch (err: any) {
          console.error(err);
        }
      },
    }),
    userLogout: builder.mutation({
      query: () => ({
        url: "/users/sign_out",
        method: "DELETE",
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(logout());
        } catch (err: any) {
          dispatch(logout());
          console.error(err);
        }
      },
    }),
  }),
});

export const { useUserLoginMutation, useUserLogoutMutation } = AuthApi;

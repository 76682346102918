import { createSlice } from "@reduxjs/toolkit";
import { AuthStateTypes } from "@types";

const initialState: AuthStateTypes = {
  isAuth: false,
  jwtToken: "",
  userData: {},
};

export const authSlices = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    login: (state, action) => {
      state.isAuth = true;
      state.jwtToken = action.payload?.jwtToken;
      state.userData = action.payload?.userData;
    },
    logout: (state) => {
      state.isAuth = false;
      state.jwtToken = "";
      state.userData = {};
    },
  },
});

export const { login, logout } = authSlices.actions;

export default authSlices.reducer;

import { Navigate, Outlet } from "react-router-dom";
import { Backdrop, Box, CircularProgress } from "@mui/material";

import { useAppSelector } from "app/hooks";

import MiniDrawer from "components/Common/MiniDrawer";
import { Main } from "layouts/LayoutStyle";

const ManagerLayout = () => {
  const isAuth = useAppSelector((state) => state.auth?.isAuth);
  const userRole = useAppSelector((state) => state.auth?.userData?.role);
  const backdropOpen = useAppSelector((state) => state.ui?.isBackdropOpen);
  const sidebarOpen = useAppSelector((state) => state.ui?.isSidebarOpen);

  return (
    <>
      {isAuth && userRole === "manager" ? (
        <Box height="100vh">
          {backdropOpen && (
            <Backdrop
              sx={{
                color: "#fff",
                zIndex: "99999",
                opacity: "0.5 !important",
                width: "100%",
              }}
              open={backdropOpen}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
          <MiniDrawer />
          <Main open={sidebarOpen} className="main-container-layout" sx={{ width: "100%" }}>
            <Box
              sx={{
                width: "100%",
                overflowX: "hidden",
                padding: "1rem",
                minHeight: "calc(100vh - 65px)",
              }}
            >
              <Outlet />
            </Box>
          </Main>
        </Box>
      ) : (
        <Navigate to="/" />
      )}
    </>
  );
};

export default ManagerLayout;

import { Tooltip } from "@mui/material";

import { TooltipPropsType } from "@types";

const TooltipAction = ({ title, placement, children, arrow }: TooltipPropsType): JSX.Element => {
  return (
    <>
      <Tooltip title={title} placement={placement} arrow={arrow}>
        {children}
      </Tooltip>
    </>
  );
};

export default TooltipAction;

import { styled } from "@mui/material/styles";
import { InputBase } from "@mui/material";

export const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "5px",
  backgroundColor: "#F9F9F9",
  marginLeft: 0,
  color: "#404041",
  fontWeight: 500,
  alignSelf: "center",
  fontSize: "12px",
  width: "100%",
  maxWidth: "500px",

  [theme.breakpoints.down("sm")]: {
    maxWidth: "100%",
  },
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "#404041",
  width: "100%",
  borderRadius: "5px",
  marginBottom: "0",
  "& .MuiInputBase-input": {
    color: "#404041",
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(0.5em + ${theme.spacing(0.5)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
}));

export const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { toast } from "react-hot-toast";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PDFThumb from "assets/images/PDF_Thumb.png";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { setBackdropState } from "store/uiSlices";
import useFormValidation from "hooks/useFormValidation";
import CloseIcon from "@mui/icons-material/Close";
import TooltipAction from "components/Common/TooltipAction";
import PDFPreview from "components/Common/PDFPreview";
import {
  useGetReimbursementMutation,
  useUpdateReimbursementStatusMutation,
  useGetUserListMutation,
  useReassignReimbursementMutation,
} from "api/ReimbursementApiSlice";

const useStyles = makeStyles({
  backButton: {
    width: "35px",
    height: "35px",
  },
  actionDiv: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media (max-width: 425px)": {
      width: "auto",
    },
  },
  thumbnailImage: {
    aspectRatio: 1,
    objectFit: "cover",
    width: "4rem",
  },
  buttonAction: {
    margin: "0 0.5rem",
    minWidth: "120px",
    "@media (max-width: 900px)": {
      minWidth: "50px",
    },
    "&:disabled": {
      color: "#fff",
    },
  },
  anchorTag: {
    textDecoration: "none",
    color: "#71abd5",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  drawer: {
    width: "40%",
  },
  accordion: {
    backgroundColor: "#FAFAFA",
    boxShadow: "none",
    marginBottom: "1rem",
    "&::before": {
      backgroundColor: "transparent ",
    },
  },
  accordionHeader: {
    fontSize: "18px",
    fontWeight: "bold",
  },
  accordionDetails: {
    backgroundColor: "white",
    borderLeft: "2px solid #EBEBEB",
    borderRight: "2px solid #EBEBEB",
    borderBottom: "2px solid #EBEBEB",
  },
  summaryHeader: {
    fontWeight: 700,
    display: "flex",
    gap: "0.3rem",
  },
  summaryDetail: {
    fontWeight: 400,
  },
  table: {
    boxShadow: "none",
  },
  tableHeader: {
    fontWeight: "bold",
  },
  tableCell: {
    verticalAlign: "top",
  },
  sectionDiv: {
    width: "100%",
    padding: "0.5rem",
  },
  sectionHeader: {
    padding: "1rem",
    backgroundColor: "#FAFAFA",
    fontSize: "18px",
    fontWeight: "bold",
  },
  sectionContent: {
    width: "100%",
    padding: "1rem 2rem",
  },
});
const declineFormValues = {
  comments: "",
};
const reassignFormValues = {
  user_id: "",
  comments: "",
};
const objectKey: any = {
  name: "Name",
  projectName: "Project Name",
  department: "Department",
  typeOfReimbursement: "Reimbursement Type",
  dateOfRequest: "Request Date",
  dateOfExpense: "Expense Date",
  typeOfPayment: "Payment Type",
  amount: "Amount",
  status: "Status",
  remarks: "Remarks",
};

const ReimbursementDetails = ({ reimbursementId, setShowDetails, refetch }: any) => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const userRole = useAppSelector((state) => state.auth?.userData?.role);
  const userId = useAppSelector((state) => state.auth?.userData?.id);
  const backdropOpen = useAppSelector((state) => state.ui?.isBackdropOpen);
  const showBackdrop = (state: boolean) => dispatch(setBackdropState(state));
  const currentPath = location.pathname.split("/")[2];

  const [reimbursementData, setReimbursementData] = useState<any>({});
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [buttonText, setButtonText] = useState<string>("");
  const [selectedAttachment, setSelectedAttachment] = useState<any>(null);
  const [showAttachmentPopup, setShowAttachmentPopup] = useState(false);
  const [showPreviewPopup, setShowPreviewPopup] = useState(false);
  const [userList, setUserList] = useState<any>([]);
  const [reassignUserId, setReassignUserId] = useState<number>(0);
  const [pdfUrl, setPdfUrl] = useState<string>("");

  const { formValues, formErrors, isValidated, handleChange, handleValidation, handleSubmitCheck } = useFormValidation(
    buttonText === "reassign" ? reassignFormValues : declineFormValues
  );
  const acceptDeclineStatuses = [
    "Pending",
    "Waiting for Manager approval",
    "Waiting for Accountant approval",
    "Waiting for Admin approval",
  ];
  const reassignStatuses = [
    "Declined by Manager",
    "Waiting for Accountant approval",
    "Declined by Accountant",
    "Waiting for Admin approval",
  ];

  const [getReimbursement] = useGetReimbursementMutation();
  const [updateReimbursementStatus] = useUpdateReimbursementStatusMutation();
  const [getUserList] = useGetUserListMutation();
  const [reassignReimbursement] = useReassignReimbursementMutation();

  useEffect(() => {
    getReimbursementData();
    getUserLists();
  }, []);

  useEffect(() => {
    if (buttonText === "accept") handleAcceptDecline();
  }, [buttonText]);

  useEffect(() => {
    handleValidation();
  }, [formValues]);

  const getReimbursementData = () => {
    showBackdrop(true);

    getReimbursement({ id: reimbursementId })
      .unwrap()
      .then((response: any) => {
        showBackdrop(false);
        setReimbursementData(response);
      })
      .catch(() => showBackdrop(false));
  };

  const getUserLists = () => {
    getUserList({ id: reimbursementId })
      .unwrap()
      .then((response: any) => {
        setUserList(response);
        setReassignUserId(response[0]?.id);
      });
  };

  const handleReassign = () => {
    showBackdrop(true);

    reassignReimbursement({
      id: reimbursementId,
      data: { action_required_by: reassignUserId, comments: formValues.comments },
    })
      .unwrap()
      .then(() => {
        showBackdrop(false);
        handleToggleDialog(false);
        setShowDetails(false);
        refetch();
        toast.success("Reassigned successfully");
      })
      .catch(() => showBackdrop(false));
  };

  const handleToggleDialog = (state: boolean) => setOpenDialog(state);

  const handleAcceptDecline = () => {
    const payloadData = {
      id: reimbursementId,
      data: { status: buttonText === "accept" ? true : false, comments: formValues.comments },
    };

    showBackdrop(true);

    updateReimbursementStatus(payloadData)
      .unwrap()
      .then(() => {
        let message = buttonText === "accept" ? "Request has been approved" : "Request has been declined";
        showBackdrop(false);
        handleToggleDialog(false);
        setShowDetails(false);
        refetch();
        toast.success(message);
      })
      .catch(() => showBackdrop(false));
  };

  const handleAttachmentClick = (attachment: any) => {
    setSelectedAttachment(attachment);

    if (attachment?.file_name.includes("pdf")) {
      setShowPreviewPopup(true);
      setPdfUrl(attachment?.attachment_url);
    } else {
      setShowAttachmentPopup(true);
    }
  };

  const renderAttachments = () => {
    if (reimbursementData?.attachments?.length > 0) {
      return reimbursementData.attachments.map((attachment: any) => (
        <Box key={attachment?.id} sx={{ display: attachment?.file_name.includes("svg") ? "none" : "block" }}>
          <img
            style={{ height: "2rem", cursor: "pointer" }}
            src={attachment?.file_name.includes("pdf") ? PDFThumb : attachment?.attachment_url}
            alt={attachment?.file_name}
            onClick={() => handleAttachmentClick(attachment)}
          />
        </Box>
      ));
    } else {
      return <p>No attachments found!</p>;
    }
  };

  const handleDownloadImage = () => {
    const link = document.createElement("a");
    link.href = selectedAttachment?.attachment_url;
    link.download = selectedAttachment?.file_name;
    link.click();
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    handleSubmitCheck();

    if (isValidated) {
      if (buttonText === "reassign") {
        handleReassign();
      } else {
        handleAcceptDecline();
      }
    }
  };

  const getStatus = (status: string) => {
    if (
      currentPath === "requests" &&
      status.toLowerCase().includes(userRole) &&
      status.toLowerCase().includes("waiting")
    ) {
      return "Pending";
    } else if (status.toLowerCase().includes("manager")) {
      return "Manager";
    } else if (status.toLowerCase().includes("accountant")) {
      return "Accountant";
    } else if (status.toLowerCase().includes("admin")) {
      return "Admin";
    }
  };

  const getBadge = (status: string) => {
    if (
      currentPath === "requests" &&
      status.toLowerCase().includes(userRole) &&
      status.toLowerCase().includes("waiting")
    ) {
      return "pending-badge";
    } else if (status.toLowerCase().includes("waiting")) {
      return "waiting-badge";
    } else if (status.toLowerCase().includes("reassigned")) {
      return "reassign-badge";
    } else if (status.toLowerCase().includes("approved")) {
      return "approved-badge";
    } else if (status.toLowerCase().includes("declined")) {
      return "declined-badge";
    }
  };

  return (
    <>
      <Box sx={{ display: backdropOpen ? "none" : "block", padding: "1.5rem", width: { xs: "100vw", md: "50vw" } }}>
        <Box sx={{ position: "absolute", top: "0", right: "0" }}>
          <TooltipAction title={"Close"} placement={"bottom"} arrow={true}>
            <IconButton size="medium" aria-label="close" color="default" onClick={() => setShowDetails(false)}>
              <CloseIcon />
            </IconButton>
          </TooltipAction>
        </Box>

        <Box component={"h2"}>{currentPath === "requests" ? "Request Details" : "Reimbursement Details"}</Box>

        <div className="justify-right" style={{ display: currentPath === "requests" ? "flex" : "none" }}>
          {userRole === "admin" && reimbursementData?.status === "Approved by Admin" ? (
            <Box
              sx={{
                display: "none",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button sx={{ mx: "0.5rem" }} variant="contained" onClick={() => setButtonText("proceed")}>
                Proceed
              </Button>
            </Box>
          ) : (
            <>
              {userId === reimbursementData?.actionRequiredBy &&
                acceptDeclineStatuses.includes(reimbursementData?.status) && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button sx={{ mx: "0.5rem" }} variant="contained" onClick={() => setButtonText("accept")}>
                      Accept
                    </Button>
                    <Button
                      sx={{ mx: "0.5rem" }}
                      variant="outlined"
                      onClick={() => {
                        handleToggleDialog(true);
                        setButtonText("decline");
                      }}
                    >
                      Decline
                    </Button>
                  </Box>
                )}
              {userId === reimbursementData?.actionRequiredBy &&
                reassignStatuses.includes(reimbursementData?.status) && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      sx={{ mx: "0.5rem" }}
                      variant="contained"
                      onClick={() => {
                        handleToggleDialog(true);
                        setButtonText("reassign");
                      }}
                    >
                      Reassign
                    </Button>
                  </Box>
                )}
            </>
          )}
        </div>

        <Box>
          <Box
            sx={{
              width: "100%",
              display: "grid",
              gridTemplateColumns: { xs: "1fr", md: "repeat(3, 1fr)" },
              gap: { xs: "1rem", md: "0.5rem" },
              padding: "2rem 0",
            }}
          >
            {Object.keys(reimbursementData).length > 0 &&
              Object.keys(reimbursementData).map((key: any, index: any) => (
                <Box
                  key={index}
                  sx={{
                    display: ["id", "actionRequiredBy", "attachments", "histories"].includes(key) ? "none" : "block",
                  }}
                >
                  {!["id", "actionRequiredBy", "attachments", "histories"].includes(key) && (
                    <Box
                      sx={{
                        display: "grid",
                        marginBottom: { xs: "0.25rem", md: "1rem" },
                        gridTemplateColumns: { xs: "1fr 1fr", md: "1fr" },
                      }}
                    >
                      <Box sx={{ fontSize: { xs: "14px", sm: "14px", md: "16px" } }} className={classes.summaryHeader}>
                        <Box>{objectKey[key]}</Box>
                      </Box>
                      <Box
                        sx={{ fontSize: { xs: "14px", sm: "14px", md: "16px" }, py: "0.5rem" }}
                        className={classes.summaryDetail}
                      >
                        {key === "status" ? (
                          <div className={`badge ${getBadge(reimbursementData[key])}`}>
                            {reimbursementData[key] !== "" ? getStatus(reimbursementData[key]) : "-"}
                          </div>
                        ) : (
                          <>{reimbursementData[key] !== "" ? reimbursementData[key] : "-"}</>
                        )}
                      </Box>
                    </Box>
                  )}
                </Box>
              ))}
          </Box>

          {reimbursementData?.attachments?.length > 0 && (
            <>
              <h4 style={{ paddingBottom: "1rem" }}>Attachments</h4>
              <Box
                sx={{
                  width: "100%",
                  display: "grid",
                  gridTemplateColumns: { xs: "1fr", md: "repeat(3, 1fr)" },
                  gap: { xs: "1rem", md: "0.5rem" },
                }}
              >
                {renderAttachments()}
              </Box>
            </>
          )}

          {reimbursementData?.histories?.length > 0 && (
            <>
              <h4 style={{ padding: "2rem 0 0 0" }}>Approval History</h4>
              <Box sx={{ padding: "0.5rem 0" }}>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableCell sx={{ fontWeight: "bold" }}>Name</TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>Department</TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>Date</TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>Status</TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>Comments</TableCell>
                    </TableHead>
                    {reimbursementData.histories.map((history: any) => (
                      <TableBody key={history?.id}>
                        <TableRow key={history?.id}>
                          <TableCell>{history?.name}</TableCell>
                          <TableCell>{history?.department}</TableCell>
                          <TableCell>{history?.date}</TableCell>
                          <TableCell>
                            <div className={`badge ${getBadge(history?.status)}`}>
                              {history?.status !== "" ? getStatus(history?.status) : "-"}
                            </div>
                          </TableCell>
                          <TableCell>{history?.comments}</TableCell>
                        </TableRow>
                      </TableBody>
                    ))}
                  </Table>
                </TableContainer>
              </Box>
            </>
          )}
          <Dialog open={openDialog} onClose={() => handleToggleDialog(false)} aria-labelledby="responsive-dialog-title">
            {buttonText === "reassign" ? (
              <>
                <Box component={"form"} onSubmit={handleSubmit} noValidate>
                  <DialogTitle id="responsive-dialog-title">{"Reassign"}</DialogTitle>
                  <DialogContent sx={{ width: { xs: "100vw", md: "30vw", height: "auto" } }}>
                    <TextField
                      label="Reassign to"
                      name="user_id"
                      onChange={(e) => setReassignUserId(Number(e.target.value))}
                      sx={{ marginTop: "1rem" }}
                      value={reassignUserId}
                      required
                      fullWidth
                      select
                      style={{ marginBottom: "1rem" }}
                      error={formErrors.user_id ? true : false}
                      helperText={formErrors.user_id ?? ""}
                    >
                      {userList.map((user: any) => (
                        <MenuItem key={user?.id} value={user?.id}>
                          {user?.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      placeholder="Comments..."
                      name="comments"
                      onChange={handleChange}
                      value={formValues.comments}
                      fullWidth
                      required
                      multiline
                      minRows={3}
                      error={formErrors.comments ? true : false}
                      helperText={formErrors.comments ?? ""}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button variant="contained" type="submit" onClick={() => {}}>
                      Submit
                    </Button>
                  </DialogActions>
                </Box>
              </>
            ) : (
              <>
                <Box component={"form"} onSubmit={handleSubmit} noValidate>
                  <DialogTitle id="responsive-dialog-title">{`Reason for ${buttonText}`}</DialogTitle>
                  <DialogContent sx={{ width: { xs: "100vw", md: "30vw" } }}>
                    <TextField
                      placeholder="Comments..."
                      name="comments"
                      onChange={handleChange}
                      value={formValues.comments}
                      fullWidth
                      required
                      multiline
                      minRows={3}
                      error={formErrors.comments ? true : false}
                      helperText={formErrors.comments ?? ""}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button variant="contained" type="submit" onClick={() => {}}>
                      {buttonText}
                    </Button>
                  </DialogActions>
                </Box>
              </>
            )}
          </Dialog>
        </Box>
      </Box>

      {showPreviewPopup && (
        <Dialog
          open={showPreviewPopup}
          onClose={() => setShowPreviewPopup(false)}
          aria-labelledby="attachment-dialog-title"
          PaperProps={{ sx: { maxWidth: "revert", width: "calc(100vw - 64px) !important" } }}
        >
          <div className="d-flex justify-center fw-700">
            <h2 id="attachment-dialog-title" className="mt-20">
              {selectedAttachment?.file_name}
            </h2>
          </div>
          <DialogContent>
            <Box component="div" sx={{ width: "calc(100% - 4px)", position: "relative" }}>
              {pdfUrl !== "" && (
                <Box sx={{ height: "100%", overflow: "auto" }}>
                  <PDFPreview pdf={pdfUrl} />
                </Box>
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowPreviewPopup(false)} variant="outlined">
              Close
            </Button>
            <Button onClick={handleDownloadImage} variant="contained">
              Download
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {showAttachmentPopup && (
        <Dialog
          open={showAttachmentPopup}
          onClose={() => setShowAttachmentPopup(false)}
          aria-labelledby="attachment-dialog-title"
        >
          <div className="d-flex justify-center fw-700">
            <h2 id="attachment-dialog-title" className="mt-20">
              {selectedAttachment?.file_name}
            </h2>
          </div>
          <DialogContent>
            <Slider className="sll">
              {reimbursementData?.attachments
                .filter((el: any) => !el?.attachment_url.includes("pdf"))
                .map((attachment: any) => (
                  <div key={attachment?.id} className="d-flex justify-center">
                    <img style={{ maxWidth: "100%" }} src={attachment?.attachment_url} alt={attachment?.file_name} />
                  </div>
                ))}
            </Slider>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowAttachmentPopup(false)} variant="outlined">
              Close
            </Button>
            <Button onClick={handleDownloadImage} variant="contained">
              Download
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default ReimbursementDetails;

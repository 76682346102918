import { createSlice } from "@reduxjs/toolkit";
import { UiStateTypes } from "@types";

const initialState: UiStateTypes = {
  isBackdropOpen: false,
  isSidebarOpen: false,
};

export const uiSlices = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setBackdropState(state, action) {
      state.isBackdropOpen = action.payload;
    },
    setSidebarState(state, action) {
      state.isSidebarOpen = action.payload;
    },
  },
});

export const { setBackdropState, setSidebarState } = uiSlices.actions;

export default uiSlices.reducer;

import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Box, Button, IconButton, InputAdornment, SwipeableDrawer, useMediaQuery, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { useAppSelector } from "app/hooks";

import ViewIcon from "@mui/icons-material/RemoveRedEye";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";

import Datatable from "components/Common/Datatable";
import ReimbursementDetails from "components/Common/ReimbursementDetails";
import { Search, StyledInputBase } from "components/Common/SearchbarStyles";

import { useGetReimbursementsMutation } from "api/ReimbursementApiSlice";

const useStyles = makeStyles({
  backButton: {
    width: "35px",
    height: "35px",
  },
  actionDiv: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media (max-width: 425px)": {
      width: "auto",
    },
  },
  amountDiv: {
    width: "100%",
    display: "flex",
    justifyContent: "right",
    alignItems: "right",
  },
  thumbnailImage: {
    aspectRatio: 1,
    objectFit: "cover",
    width: "4rem",
  },
  buttonAction: {
    margin: "0 0.5rem !important",
    minWidth: "120px !important",
    "@media (max-width: 900px)": {
      minWidth: "50px !important",
    },
    "&:disabled": {
      color: "#fff",
    },
  },
  anchorTag: {
    textDecoration: "none",
    color: "#71abd5",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  drawer: {
    width: "40%",
  },
});

const columnAliasNames: any = {
  typeOfReimbursement: "reimbursement_type",
  dateOfRequest: "created_at",
  typeOfPayment: "payment_mode",
  amount: "amount",
  status: "status",
};

const RequestsPage = () => {
  const classes = useStyles();
  const theme = useTheme();
  const location = useLocation();

  const isLessThanTabletScreen = useMediaQuery(theme.breakpoints.down("md"));
  const userRole = useAppSelector((state) => state.auth?.userData?.role);

  const [reimbursementsData, setReimbursementsData] = useState<any>([]);
  const [dataLength, setDataLength] = useState<number>(0);
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [reimbursementId, setReimbursementId] = useState<number>(0);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [sortColumn, setSortColumn] = useState<string>("");
  const [sortOrder, setSortOrder] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");
  const currentPath = location.pathname.split("/")[2];

  const [getReimbursements, { isLoading }] = useGetReimbursementsMutation();

  useEffect(() => {
    getReimbursementsData();
  }, [paginationModel, sortColumn, sortOrder, searchText]);

  const getReimbursementsData = () => {
    getReimbursements({
      url: `/reimbursements?page=${paginationModel?.page + 1}&per_page=${
        paginationModel?.pageSize
      }&sort_column=${sortColumn}&sort_order=${sortOrder}&search=${searchText}&request_type=management`,
    })
      .unwrap()
      .then((response: any) => {
        setReimbursementsData(response?.reimbursements);
        setDataLength(response?.totalCount);
      });
  };

  const refetch = () => {
    setPaginationModel({
      page: 0,
      pageSize: 10,
    });
  };

  const handleViewClick = (row: any) => {
    setReimbursementId(Number(row?.id));
    setShowDetails(true);
  };

  const handleSortColumnChange = (sortModel: any) => {
    if (sortModel?.length > 0) {
      setSortColumn(columnAliasNames[sortModel[0].field]);
      setSortOrder(sortModel[0].sort);
    } else {
      setSortColumn("");
      setSortOrder("");
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaginationModel({
      page: 0,
      pageSize: 10,
    });
    setSearchText(event.currentTarget.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && searchText !== "") {
      setPaginationModel({
        page: 0,
        pageSize: 10,
      });
    }
  };

  const handleCloseSearch = () => {
    setPaginationModel({
      page: 0,
      pageSize: 10,
    });
    setSearchText("");
  };

  const getStatus = (status: string) => {
    if (
      currentPath === "requests" &&
      status.toLowerCase().includes(userRole) &&
      status.toLowerCase().includes("waiting")
    ) {
      return "Pending";
    } else if (status.toLowerCase().includes("manager")) {
      return "Manager";
    } else if (status.toLowerCase().includes("accountant")) {
      return "Accountant";
    } else if (status.toLowerCase().includes("admin")) {
      return "Admin";
    }
  };

  const getBadge = (status: string) => {
    if (
      currentPath === "requests" &&
      status.toLowerCase().includes(userRole) &&
      status.toLowerCase().includes("waiting")
    ) {
      return "pending-badge";
    } else if (status.toLowerCase().includes("waiting")) {
      return "waiting-badge";
    } else if (status.toLowerCase().includes("reassigned")) {
      return "reassign-badge";
    } else if (status.toLowerCase().includes("approved")) {
      return "approved-badge";
    } else if (status.toLowerCase().includes("declined")) {
      return "declined-badge";
    }
  };

  const tableHeaders: any = [
    {
      field: "name",
      headerName: "Name",
      width: 200,
      sortable: false,
      renderCell: (params: any) => {
        const name = params?.row?.name;

        return <Box style={{ wordBreak: "break-word" }}>{name}</Box>;
      },
    },
    { field: "department", headerName: "Department", width: 150, sortable: false },
    { field: "typeOfReimbursement", headerName: "Reimbursement Type", width: 200, sortable: true },
    { field: "dateOfRequest", headerName: "Request Date", width: 150, sortable: true },
    { field: "typeOfPayment", headerName: "Payment Type ", width: 150, sortable: true },
    {
      field: "amount",
      headerName: "Amount",
      width: 120,
      sortable: true,
      headerAlign: "right",
      renderCell: (params: any) => {
        const amount = params?.row?.amount;

        return <Box className={classes.amountDiv}>{amount}</Box>;
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      sortable: true,
      headerAlign: "center",
      renderCell: (params: any) => {
        const status = params?.row?.status;

        return (
          <Box className={`badge ${getBadge(status)}`} style={{ marginLeft: "40px" }}>
            {getStatus(status)}
          </Box>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: isLessThanTabletScreen ? 100 : 150,
      sortable: false,
      headerAlign: "center",
      renderCell: (params: any) => {
        return (
          <Box className={classes.actionDiv}>
            <Button
              className={classes.buttonAction}
              size="small"
              variant="contained"
              onClick={() => handleViewClick(params?.row)}
            >
              {isLessThanTabletScreen ? <ViewIcon /> : <>View</>}
            </Button>
          </Box>
        );
      },
    },
  ];

  const SmallBox = ({ color }: any) => {
    return <Box className="box-indicator" bgcolor={color} />;
  };

  return (
    <>
      <h2>Requests</h2>
      <Box sx={{ marginTop: "0.5rem" }}>
        <Search style={{ height: "40px" }}>
          <StyledInputBase
            placeholder="Search by reimbursement type, payment type, status"
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            value={searchText}
            startAdornment={
              <InputAdornment position="start" sx={{ paddingLeft: "10px" }}>
                <SearchIcon />
              </InputAdornment>
            }
            endAdornment={
              searchText && (
                <IconButton aria-label="close" onClick={handleCloseSearch}>
                  <CloseIcon />
                </IconButton>
              )
            }
          />
        </Search>
      </Box>
      <Box sx={{ width: "100%", padding: "1.5rem 0" }}>
        <Datatable
          type={"request"}
          isLoading={isLoading}
          headers={tableHeaders}
          data={reimbursementsData}
          rowCount={dataLength}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
          onSortColumnChange={handleSortColumnChange}
        />
        <div style={{ display: reimbursementsData?.length > 0 ? "flex" : "none" }}>
          <div className="box-content">
            <SmallBox color="grey" />
            <p>Pending</p>
          </div>
          <div className="box-content">
            <SmallBox color="orange" />
            <p>Waiting</p>
          </div>
          <div className="box-content">
            <SmallBox color="pink" />
            <p>Reassigned</p>
          </div>
          <div className="box-content">
            <SmallBox color="green" />
            <p>Approved</p>
          </div>
          <div className="box-content">
            <SmallBox color="red" />
            <p>Declined</p>
          </div>
        </div>
        {showDetails && (
          <SwipeableDrawer
            anchor={"right"}
            open={showDetails}
            onOpen={() => setShowDetails(true)}
            onClose={() => setShowDetails(false)}
          >
            <ReimbursementDetails reimbursementId={reimbursementId} setShowDetails={setShowDetails} refetch={refetch} />
          </SwipeableDrawer>
        )}
      </Box>
    </>
  );
};

export default RequestsPage;

import { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import axios from "axios";
import { toast } from "react-hot-toast";

import CloseIcon from "@mui/icons-material/Close";
import { FileUploadOutlined } from "@mui/icons-material";

import { useAppDispatch, useAppSelector } from "app/hooks";
import { setBackdropState } from "store/uiSlices";

import TooltipAction from "components/Common/TooltipAction";
import useFormValidation from "hooks/useFormValidation";

import { useGetProjectsMutation } from "api/ProjectApiSlice";

const API_BASE_URL = process.env.REACT_APP_RI_API_BASE_URL;
const reimbursementList: any = [
  {
    id: 1,
    name: "Travel",
  },
  {
    id: 2,
    name: "Food",
  },
  {
    id: 3,
    name: "Medical",
  },
  {
    id: 4,
    name: "Telephone",
  },
  {
    id: 5,
    name: "Fuel",
  },
  {
    id: 6,
    name: "Imprest",
  },
  {
    id: 7,
    name: "Other",
  },
  {
    id: 8,
    name: "Product Development",
  },
];
const defaultFormValues = {
  project_id: [],
  reimbursement_type: [],
  expense_date: "",
  payment_mode: "",
  amount: "",
  remarks: "",
  attachments: [],
};
let source = axios.CancelToken.source();

const ClaimReimbursement = ({ setShowForm, refetch }: any) => {
  const dispatch = useAppDispatch();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [projectList, setProjectList] = useState<any>([]);
  const [optionList, setOptionList] = useState<any>([]);

  const showBackdrop = (state: boolean) => dispatch(setBackdropState(state));
  const jwtToken = useAppSelector((state) => state.auth?.jwtToken);

  const {
    formValues,
    setFormValues,
    formErrors,
    isValidated,
    handleChange,
    handleSelectChange,
    handleDateChange,
    handleCheckboxChange,
    handleFileChange,
    handleValidation,
    handleSubmitCheck,
  } = useFormValidation(defaultFormValues);

  const [getProjects] = useGetProjectsMutation();

  useEffect(() => {
    getProjectList();
  }, []);

  useEffect(() => {
    let arrayObj = projectList.map((item: any) => {
      return {
        id: item?.id,
        label: item?.name,
        isActive: item?.isActive,
      };
    });

    setOptionList(arrayObj);
  }, [projectList]);

  useEffect(() => {
    handleValidation();
  }, [formValues]);

  useEffect(() => {
    setFormValues({ ...formValues, expense_date: "" });
  }, [formValues.payment_mode]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleSubmitCheck();

    if (isValidated) {
      handleFileUpload();
    }
  };

  const handleFileUpload = () => {
    setLoading(true);
    showBackdrop(true);

    let formData = new FormData();
    let projectIds = formValues.project_id.map((project: any) => project.id);

    formData.append("project_id", projectIds);
    formData.append("reimbursement_type", formValues?.reimbursement_type.join(", "));
    formData.append("expense_date", formValues?.expense_date);
    formData.append("payment_mode", formValues?.payment_mode);
    formData.append("amount", formValues?.amount);
    formData.append("remarks", formValues?.remarks);

    for (let attachment of formValues?.attachments) {
      formData.append("attachments[]", attachment);
    }

    axios({
      method: "POST",
      url: `${API_BASE_URL}/reimbursements`,
      headers: {
        Authorization: jwtToken,
        "Content-Type": "multipart/form-data",
      },
      data: formData,
      cancelToken: source.token,
    })
      .then(() => {
        setLoading(false);
        showBackdrop(false);
        setShowForm(false);
        setFormValues(defaultFormValues);
        refetch();
        toast.success("Claim reimbursement request has been sent successfully");
      })
      .catch(() => {
        setLoading(false);
        showBackdrop(false);
      });
  };

  const getProjectList = () => {
    getProjects({})
      .unwrap()
      .then((response: any) => {
        setProjectList(response.filter((item: any) => item?.isActive));
      });
  };

  return (
    <Box sx={{ padding: "1.5rem", width: { xs: "100vw", md: "30vw" } }}>
      <Box sx={{ position: "absolute", top: "0", right: "0" }}>
        <TooltipAction title={"Close"} placement={"bottom"} arrow={true}>
          <IconButton size="medium" aria-label="close" color="default" onClick={() => setShowForm(false)}>
            <CloseIcon />
          </IconButton>
        </TooltipAction>
      </Box>

      <Box component={"h2"}>Claim a Request</Box>

      <Box
        sx={{ py: "0.875rem", display: "grid", gap: "0.625rem" }}
        component={"form"}
        onSubmit={handleSubmit}
        noValidate
      >
        <Box sx={{ paddingBottom: "1rem" }}>
          <Autocomplete
            options={optionList}
            onChange={handleSelectChange}
            value={formValues.project_id}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Project Name"
                error={formErrors.project_id ? true : false}
                helperText={formErrors.project_id ?? ""}
                fullWidth
                required={formValues.project_id.length === 0}
              />
            )}
            filterSelectedOptions
            multiple
            readOnly={isLoading}
          />
        </Box>
        <Box sx={{ paddingBottom: "1rem" }}>
          <FormControl error={formErrors.reimbursement_type ? true : false} required>
            <FormLabel>Reimbursement Type</FormLabel>
            {reimbursementList.map((reimbursement: any) => (
              <FormControlLabel
                key={reimbursement?.id}
                control={
                  <Checkbox
                    name="reimbursement_type"
                    value={reimbursement?.name}
                    checked={formValues?.reimbursement_type.includes(reimbursement?.name)}
                    onChange={handleCheckboxChange}
                  />
                }
                label={reimbursement?.name}
              />
            ))}
            <FormHelperText>{formErrors.reimbursement_type ?? ""}</FormHelperText>
          </FormControl>
        </Box>

        <Box sx={{ paddingBottom: "1rem" }}>
          <FormControl error={formErrors.payment_mode ? true : false} required>
            <RadioGroup row name="payment_mode" onChange={handleChange}>
              <FormControlLabel value="Advance" control={<Radio />} label="Advance-Payment" />
              <FormControlLabel value="Post-Payment" control={<Radio />} label="Post-Payment" />
            </RadioGroup>
            <FormHelperText>{formErrors.payment_mode ?? ""}</FormHelperText>
          </FormControl>
        </Box>

        <Box sx={{ paddingBottom: "1rem" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Expense Date"
              value={dayjs(formValues.expense_date)}
              onChange={(newValue) => handleDateChange("expense_date", newValue)}
              slotProps={{
                textField: {
                  helperText: formErrors.expense_date ?? "",
                  error: formErrors.expense_date ? true : false,
                  required: true,
                  fullWidth: true,
                  disabled: true,
                },
              }}
              minDate={formValues.payment_mode === "Advance" ? dayjs().startOf("day") : undefined}
              maxDate={formValues.payment_mode === "Post-Payment" ? dayjs().startOf("day") : undefined}
            />
          </LocalizationProvider>
        </Box>

        <Box sx={{ paddingBottom: "1rem" }}>
          <TextField
            label="Amount"
            name="amount"
            onChange={handleChange}
            value={formValues.amount}
            error={formErrors.amount ? true : false}
            helperText={formErrors.amount ?? ""}
            InputProps={{ readOnly: isLoading }}
            fullWidth
            required
            type="number"
          />
        </Box>
        <Box sx={{ paddingBottom: "1rem" }}>
          <TextField
            label="Remarks"
            name="remarks"
            onChange={handleChange}
            value={formValues.remarks}
            error={formErrors.remarks ? true : false}
            helperText={formErrors.remarks ?? ""}
            InputProps={{ readOnly: isLoading }}
            fullWidth
            required
            multiline
            minRows={3}
          />
        </Box>
        {formValues?.payment_mode === "Post-Payment" && (
          <Box sx={{ paddingBottom: "1rem" }}>
            <TextField
              placeholder="Attachments"
              value={
                formValues?.attachments?.length === 0
                  ? ""
                  : formValues?.attachments?.length > 1
                  ? `${formValues?.attachments?.length} files`
                  : `${formValues?.attachments?.length} file`
              }
              InputProps={{
                endAdornment: (
                  <IconButton component="label">
                    <FileUploadOutlined />
                    <input
                      name="attachments"
                      style={{ display: "none" }}
                      type="file"
                      hidden
                      onChange={handleFileChange}
                      accept="image/jpg, image/jpeg, image/png, application/pdf"
                      multiple
                    />
                  </IconButton>
                ),
              }}
              fullWidth
              error={formErrors.attachments ? true : false}
              helperText={formErrors.attachments ?? ""}
            />
          </Box>
        )}
        <Box>
          <Button type="submit" variant="contained" size="medium" onClick={() => {}} disabled={isLoading}>
            {"Send Request"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ClaimReimbursement;

import { useEffect } from "react";
import { Box, Button, FormControl, FormControlLabel, FormLabel, IconButton, Switch, TextField } from "@mui/material";
import { toast } from "react-hot-toast";

import { useAppDispatch, useAppSelector } from "app/hooks";
import { setBackdropState } from "store/uiSlices";

import CloseIcon from "@mui/icons-material/Close";

import TooltipAction from "components/Common/TooltipAction";
import useFormValidation from "hooks/useFormValidation";

import { useCreateProjectMutation, useUpdateProjectMutation } from "api/ProjectApiSlice";

const defaultFormValues = {
  project_name: "",
  is_active: false,
};

const Project = ({ actionType, formData, setShowForm, refetch }: any) => {
  const dispatch = useAppDispatch();
  const {
    formValues,
    setFormValues,
    formErrors,
    isValidated,
    handleChange,
    handleToggleChange,
    handleValidation,
    handleSubmitCheck,
  } = useFormValidation(defaultFormValues);

  const backdropOpen = useAppSelector((state) => state.ui?.isBackdropOpen);
  const showBackdrop = (state: boolean) => dispatch(setBackdropState(state));

  const [createProject] = useCreateProjectMutation();
  const [updateProject] = useUpdateProjectMutation();

  useEffect(() => {
    if (actionType === "Update") {
      setFormValues({ project_name: formData?.name, is_active: formData?.isActive });
    }
  }, []);

  useEffect(() => {
    handleValidation();
  }, [formValues]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    handleSubmitCheck();

    if (isValidated) {
      showBackdrop(true);

      if (actionType === "Create") {
        createProject({ project: formValues })
          .unwrap()
          .then(() => {
            showBackdrop(false);
            setShowForm(false);
            setFormValues(defaultFormValues);
            refetch();
            toast.success("Project created successsfully");
          })
          .catch(() => showBackdrop(false));
      }

      if (actionType === "Update") {
        updateProject({ id: formData?.id, data: { project: formValues } })
          .unwrap()
          .then(() => {
            showBackdrop(false);
            setShowForm(false);
            setFormValues(defaultFormValues);
            refetch();
            toast.success("Project updated successsfully");
          })
          .catch(() => showBackdrop(false));
      }
    }
  };

  return (
    <Box sx={{ padding: "1.5rem", width: { xs: "100vw", md: "30vw" } }}>
      <Box sx={{ position: "absolute", top: "0", right: "0" }}>
        <TooltipAction title={"Close"} placement={"bottom"} arrow={true}>
          <IconButton size="medium" aria-label="close" color="default" onClick={() => setShowForm(false)}>
            <CloseIcon />
          </IconButton>
        </TooltipAction>
      </Box>

      <Box component={"h2"}>{actionType} project</Box>

      <Box
        sx={{ py: "0.875rem", display: "grid", gap: "0.625rem" }}
        component={"form"}
        onSubmit={handleSubmit}
        noValidate
      >
        <Box sx={{ paddingBottom: "1rem" }}>
          <TextField
            label="Project Name"
            name="project_name"
            onChange={handleChange}
            value={formValues.project_name}
            error={formErrors.project_name ? true : false}
            helperText={formErrors.project_name ?? ""}
            InputProps={{ readOnly: backdropOpen }}
            fullWidth
            required
          />
        </Box>
        <Box sx={{ paddingBottom: "1rem" }}>
          <FormControl>
            <FormLabel>Status</FormLabel>
            <FormControlLabel
              control={<Switch name="is_active" checked={formValues?.is_active} onChange={handleToggleChange} />}
              label={formValues?.is_active ? "Active" : "Inactive"}
            />
          </FormControl>
        </Box>
        <Box>
          <Button type="submit" variant="contained" size="medium" onClick={() => {}} disabled={backdropOpen}>
            {actionType}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Project;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import type { RootState } from "app/store";
import _ from "lodash";

const API_BASE_URL = process.env.REACT_APP_RI_API_BASE_URL;

export const ReimbursementApi = createApi({
  reducerPath: "reimbursementAPIs",
  baseQuery: fetchBaseQuery({
    baseUrl: API_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const jwtToken = (getState() as RootState).auth?.jwtToken;

      if (jwtToken) {
        headers.set("Authorization", jwtToken);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getReimbursements: builder.mutation({
      query: (body) => ({
        url: body.url,
        method: "GET",
      }),
      transformResponse: (response: any, _meta: any) => {
        let apiResponse = response?.data?.reimbursement;
        let apiResponseCount = response?.data?.total_count;
        let reimbursements: any = [];

        if (_.isArray(apiResponse)) {
          reimbursements = apiResponse.map((reimbursement: any) => {
            return {
              id: reimbursement?.id,
              name: reimbursement?.name,
              department: reimbursement?.department,
              typeOfReimbursement: reimbursement?.type_of_reimbursement,
              dateOfRequest: reimbursement?.date_of_request,
              typeOfPayment: reimbursement?.type_of_payment,
              amount: reimbursement?.amount,
              status: reimbursement?.status,
            };
          });
        }

        return {
          totalCount: apiResponseCount,
          reimbursements: reimbursements,
        };
      },
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (err: any) {
          console.error(err);
        }
      },
    }),
    getReimbursement: builder.mutation({
      query: (body) => ({
        url: `/reimbursements/${body?.id}`,
        method: "GET",
      }),
      transformResponse: (response: any, _meta: any) => {
        let apiResponse = response?.data ? response?.data : {};

        return {
          id: apiResponse?.id,
          actionRequiredBy: apiResponse?.action_required_by,
          name: apiResponse?.name,
          projectName: apiResponse?.project_name,
          department: apiResponse?.department,
          typeOfReimbursement: apiResponse?.type_of_reimbursement,
          dateOfRequest: apiResponse?.date_of_request,
          dateOfExpense: apiResponse?.date_of_expense,
          typeOfPayment: apiResponse?.type_of_payment,
          amount: apiResponse?.amount,
          status: apiResponse?.status,
          remarks: apiResponse?.remarks,
          attachments: apiResponse?.attachments,
          histories: apiResponse?.histories,
        };
      },
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (err: any) {
          console.error(err);
        }
      },
    }),
    createReimbursement: builder.mutation({
      query: (body) => ({
        url: "/reimbursements",
        method: "POST",
        body: body,
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (err: any) {
          console.error(err);
        }
      },
    }),
    updateReimbursement: builder.mutation({
      query: (body) => ({
        url: `/reimbursements/${body?.id}`,
        method: "PUT",
        body: body?.data,
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (err: any) {
          console.error(err);
        }
      },
    }),
    deleteReimbursement: builder.mutation({
      query: (body) => ({
        url: `/reimbursements/${body?.id}`,
        method: "DELETE",
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (err: any) {
          console.error(err);
        }
      },
    }),
    updateReimbursementStatus: builder.mutation({
      query: (body) => ({
        url: `/reimbursements/${body?.id}/update_reimbursement_status`,
        method: "POST",
        body: body?.data,
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (err: any) {
          console.error(err);
        }
      },
    }),
    getUserList: builder.mutation({
      query: (body) => ({
        url: `/user_email_list/${body?.id}`,
        method: "GET",
      }),
      transformResponse: (response: any, _meta: any) => {
        let apiResponse = response?.data;

        return apiResponse.map((user: any) => {
          return {
            id: user?.id,
            name: user?.name,
          };
        });
      },
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (err: any) {
          console.error(err);
        }
      },
    }),
    reassignReimbursement: builder.mutation({
      query: (body) => ({
        url: `/reassign_request/${body?.id}`,
        method: "PATCH",
        body: body?.data,
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (err: any) {
          console.error(err);
        }
      },
    }),
  }),
});

export const {
  useGetReimbursementsMutation,
  useGetReimbursementMutation,
  useCreateReimbursementMutation,
  useUpdateReimbursementMutation,
  useDeleteReimbursementMutation,
  useUpdateReimbursementStatusMutation,
  useGetUserListMutation,
  useReassignReimbursementMutation,
} = ReimbursementApi;

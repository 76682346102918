import { useState } from "react";
import dayjs from "dayjs";

const useFormValidation = (defaultFormValues: any) => {
  const [formValues, setFormValues] = useState<any>(defaultFormValues);
  const [formErrors, setFormErrors] = useState<any>({});
  const [isValidated, setValidated] = useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let currentTarget = event.currentTarget || event.target;
    let name = currentTarget.name;
    let value = currentTarget.value;

    setFormValues({ ...formValues, [name]: value });
    setFormErrors({ ...formErrors, [name]: handleErrorMessage(name, value) });
  };

  const handleSelectChange = (event: React.SyntheticEvent, value: any, reason: string) => {
    let name = "project_id";

    setFormValues({ ...formValues, [name]: value });
    setFormErrors({ ...formErrors, [name]: handleErrorMessage(name, value) });
  };

  const handleDateChange = (name: string, value: any) => {
    let dateString = dayjs(value).format("YYYY-MM-DD");

    setFormValues({ ...formValues, [name]: dateString });
    setFormErrors({
      ...formErrors,
      [name]: handleErrorMessage(name, dateString),
    });
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let currentTarget = event.currentTarget || event.target;
    let name = currentTarget.name;
    let value = currentTarget.value;
    let isChecked = currentTarget.checked;

    if (isChecked) {
      let checkboxValues = [...formValues?.reimbursement_type, value];
      setFormValues({ ...formValues, [name]: checkboxValues });
      setFormErrors({ ...formErrors, [name]: handleErrorMessage(name, checkboxValues) });
    } else {
      let checkboxValues = formValues?.reimbursement_type.filter((item: string) => item !== value);
      setFormValues({ ...formValues, [name]: checkboxValues });
      setFormErrors({ ...formErrors, [name]: handleErrorMessage(name, checkboxValues) });
    }
  };

  const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let currentTarget = event.currentTarget || event.target;
    let name = currentTarget.name;
    let value = currentTarget.checked;

    setFormValues({ ...formValues, [name]: value });
    setFormErrors({ ...formErrors, [name]: handleErrorMessage(name, value) });
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let currentTarget = event.currentTarget || event.target;
    let name = currentTarget.name;
    let files = event.currentTarget.files as FileList;
    let uploadFiles: any = [];

    Array.from(files).forEach((file: any) => {
      uploadFiles.push(file);
    });

    setFormValues({ ...formValues, attachments: uploadFiles });
    setFormErrors({ ...formErrors, [name]: handleErrorMessage(name, uploadFiles) });
  };

  const handleValidation = () => {
    let emptyFields: any = Object.keys(formValues).filter((key) => {
      return ["reimbursement_type", "attachments"].includes(key)
        ? formValues[key].length === 0
        : formValues[key] === "";
    });

    if (formValues?.payment_mode === "Advance") {
      emptyFields = emptyFields.filter((field: any) => !["attachments"].includes(field));
    }

    let errors: any = Object.keys(formErrors).filter((key) => {
      return formErrors[key] !== "";
    });

    if (emptyFields.length === 0 && errors.length === 0) {
      setValidated(true);
    } else {
      setValidated(false);
    }
  };

  const handleSubmitCheck = () => {
    let error: any = {};

    Object.keys(formValues).forEach((key) => {
      error[key] = handleErrorMessage(key, formValues[key]);
    });

    setFormErrors(error);
  };

  const handleErrorMessage = (name: string, value: any) => {
    let errorMessage: string = "";

    switch (name) {
      case "expense_date":
      case "payment_mode":
      case "amount":
      case "remarks":
      case "country":
      case "user_id":
      case "comments":
        if (value === "") {
          errorMessage = "Required field";
        }
        break;
      case "project_id":
      case "reimbursement_type":
        if (value.length === 0) {
          errorMessage = "Required field";
        }
        break;
      case "attachments":
        if (formValues?.payment_mode === "Post-Payment" && value.length === 0) {
          errorMessage = "Required field";
        }
        break;
      default:
        break;
    }
    return errorMessage;
  };

  return {
    formValues,
    setFormValues,
    formErrors,
    setFormErrors,
    isValidated,
    setValidated,
    handleChange,
    handleSelectChange,
    handleDateChange,
    handleCheckboxChange,
    handleToggleChange,
    handleFileChange,
    handleValidation,
    handleSubmitCheck,
  };
};

export default useFormValidation;

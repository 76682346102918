import { toast } from "react-hot-toast";
import { logout } from "store/authSlices";

const apiInterceptorMiddleware =
  ({ dispatch }: any) =>
  (next: any) =>
  (action: any) => {
    if (action.meta?.arg?.type) {
      if (action.meta?.requestStatus === "rejected") {
        let errorPayload = action.payload;

        if (errorPayload.status === 400) {
          toast.error(errorPayload.data?.error || "Something went wrong!");
        } else if (errorPayload.status === 401 || errorPayload.originalStatus === 401) {
          toast.error(errorPayload.data?.error || "Session expired, please log in again!");
          dispatch(logout());
        } else if (errorPayload.status === 403) {
          toast.error(errorPayload.data?.error || "Something went wrong!");
          dispatch(logout());
        } else if (errorPayload.status === 500) {
          toast.error(errorPayload.data?.error || "Internal Server Error!");
        } else {
          toast.error(errorPayload.data?.error || "Something went wrong!");
        }
      }
    }

    return next(action);
  };

export default apiInterceptorMiddleware;

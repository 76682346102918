import { Navigate, Outlet } from "react-router-dom";
import { useAppSelector } from "app/hooks";

const AuthenticationLayout = () => {
  const isAuth = useAppSelector((state) => state.auth?.isAuth);
  const userRole = useAppSelector((state) => state.auth?.userData?.role);

  return (
    <>
      {isAuth ? (
        userRole === "admin" ? (
          <Navigate to={`/${userRole}/analytics`} />
        ) : userRole === "accountant" ? (
          <Navigate to={`/${userRole}/requests`} />
        ) : (
          <Navigate to={`/${userRole}/reimbursements`} />
        )
      ) : (
        <Outlet />
      )}
    </>
  );
};

export default AuthenticationLayout;

import { AnalyticsApi } from "./AnalyticsApiSlice";
import { AuthApi } from "api/AuthApiSlice";
import { ProjectApi } from "./ProjectApiSlice";
import { ReimbursementApi } from "./ReimbursementApiSlice";
import apiInterceptorMiddleware from "api/ApiInterceptor";

export const combinedAPIReducer = {
  [AnalyticsApi.reducerPath]: AnalyticsApi.reducer,
  [AuthApi.reducerPath]: AuthApi.reducer,
  [ProjectApi.reducerPath]: ProjectApi.reducer,
  [ReimbursementApi.reducerPath]: ReimbursementApi.reducer,
};

export const apiMiddlewares = [
  AnalyticsApi.middleware,
  AuthApi.middleware,
  ProjectApi.middleware,
  ReimbursementApi.middleware,
  apiInterceptorMiddleware,
];

const allAPIs = [AnalyticsApi, AuthApi, ProjectApi, ReimbursementApi];

export default allAPIs;

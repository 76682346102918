import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const PopupDialog = ({ popupOpen, message, handleDelete, handleClose }: any) => {
  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <>
      <Dialog fullScreen={fullScreen} open={popupOpen} onClose={handleClose}>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button type="button" variant="outlined" size="medium" sx={{ mx: "0.5rem" }} onClick={handleClose}>
            No
          </Button>
          <Button type="button" variant="contained" size="medium" sx={{ mx: "0.5rem" }} onClick={handleDelete}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PopupDialog;

import GoogleLoginComponent from "components/Authentication/GoogleLoginComponent";

const LoginPage = () => {
  return (
    <>
      <GoogleLoginComponent />
    </>
  );
};

export default LoginPage;
